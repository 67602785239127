import { Loader } from '@unbounce/ui-components';
import React from 'react';

interface PageLoaderProps {
  title?: string;
  description?: string;
}

const PageLoader = (props: PageLoaderProps) => {
  return <Loader {...props} />;
};

export default PageLoader;
