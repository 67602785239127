import classnames from "classnames";
import React, { ReactNode } from "react";

import styles from "./ModalFooter.css";

interface ModalFooterProps {
  children: ReactNode;
  layout?: string;
}

const ModalFooter = ({ children, layout = "right" }: ModalFooterProps) => (
  <footer className={classnames([styles.footer, styles[layout]])}>
    {children}
  </footer>
);

export default ModalFooter;
