import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from '../../Modal/Modal';

import { Button } from '@unbounce/ui-components';

import DoNotShow from './../../Modal/DoNotShow';
import ModalFooter from './../../Modal/ModalFooter';
import RetryModalContent from './RetryModalContent';
import { Props as StatusLabelProps } from './StatusLabel';

interface RetryButtonProps {
  label: string;
  modal: {
    title: string;
    footer: boolean;
  };
  showModal: boolean;
  actions: {
    doNotShowModal: string;
    retryDomainSetup: string;
  };
  visible: boolean;

  setLoading: Dispatch<SetStateAction<boolean>>;
  setStatus: Dispatch<SetStateAction<StatusLabelProps>>;
  setShowRetry: Dispatch<SetStateAction<boolean>>;
}

// See DomainsController#retry_setup
interface RetrySetupResponseJson {
  domain: {
    status: StatusLabelProps;
  };
}

const RetryButton = ({
  actions,
  setLoading,
  setStatus,
  setShowRetry,
  label,
  modal
}: RetryButtonProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [doNotShow, setDoNotShow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!window.ubReactGlobal) {
      window.ubReactGlobal = {};
    }
  });

  const closeModal = () => {
    if (doNotShow) {
      fetch(actions.doNotShowModal, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': window.form_authenticity_token
        },
        xhrFields: { withCredentials: true },
        body: JSON.stringify({
          hide_retry_domain_setup_modal: doNotShow
        })
      }).then(() => {
        window.ubReactGlobal = { doNotShow };
        setShowModal(false);
      });
    }

    setModalIsOpen(false);
  };

  const handleClick = () => {
    if (showModal && !window.ubReactGlobal.doNotShow) {
      setModalIsOpen(true);
    } else {
      retryDomainSetup();
    }
  };

  const retryDomainSetup = () => {
    setLoading(true);

    fetch(actions.retryDomainSetup, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': window.form_authenticity_token
      },
      xhrFields: {
        withCredentials: true
      },
      body: JSON.stringify({
        hide_retry_domain_setup_modal: doNotShow
      })
    })
      .then((response) => response.json() as Promise<RetrySetupResponseJson>)
      .then((response) => {
        setStatus(response.domain.status);
        setShowRetry(false);
        setLoading(false);

        window.ubReactGlobal = { doNotShow };

        setShowModal(false);
      });
  };

  return (
    <>
      <Button onClick={handleClick} secondary>
        {label}
      </Button>

      <Modal title={modal.title} modalIsOpen={modalIsOpen} handleCloseModal={closeModal}>
        <RetryModalContent />
        <ModalFooter layout="rightWithLeftElm">
          <DoNotShow
            active={doNotShow}
            change={() => {
              setDoNotShow(!doNotShow);
            }}
          />
          <Button secondary onClick={closeModal} key="foot-1">
            Cancel
          </Button>
          <Button onClick={retryDomainSetup} key="foot-2">
            Got it, Retry Domain Setup
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RetryButton;
