import React from 'react';
import HeaderSearch from './../../Basic/Headers/HeaderSearch';
import Subheader from './../../Basic/Headers/Subheader';
import { ButtonLink } from '@unbounce/ui-components';
import styles from './Users.css';

interface LoadStateProps {
  isActive: boolean;
  isPending: boolean;
}

interface UsersSubheaderProps extends LoadStateProps {
  activeUser: {
    label: string;
    href: string;
    count: string;
  };
  pendingInvitation: {
    label: string;
    href: string;
    count: string;
  };
}

const UsersSubheader = ({
  activeUser,
  pendingInvitation,
  isActive,
  isPending
}: UsersSubheaderProps) => (
  <Subheader className={styles.subheader}>
    <div className={styles.filters}>
      <ButtonLink
        secondary
        href={activeUser.href}
        className={`${styles.filterButton} ${isActive ? styles.selected : ''}`}
      >
        <React.Fragment>
          {activeUser.label} {activeUser.count}
        </React.Fragment>
      </ButtonLink>
      <ButtonLink
        secondary
        href={pendingInvitation.href}
        className={`${styles.filterButton} ${isPending ? styles.selected : ''}`}
      >
        <React.Fragment>
          {pendingInvitation.label} {pendingInvitation.count}
        </React.Fragment>
      </ButtonLink>
    </div>
    <HeaderSearch placeholder="Search Users..." styles={styles.searchbar} />
  </Subheader>
);

export default UsersSubheader;
