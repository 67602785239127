import { Button, ButtonLink } from '@unbounce/ui-components';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import styles from './Subscriptions.css';

const Subscriptions = function ({
  accountName,
  planName
}: {
  accountName: string;
  planName: string;
}) {
  const openZendesk = (e: React.MouseEvent | React.FormEvent) => {
    // @ts-ignore
    if (window.zE) {
      e.preventDefault();
      // @ts-ignore
      window.zE.activate();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h2>{`Hi, ${accountName}`}</h2>
        <p
          className={styles.description}
        >{`If you'd like to change your ${planName} plan, we have new plans available to suit you.`}</p>
        <p className={styles.description}>
          Have a look at our new offerings, or contact us now to upgrade.
        </p>
        <div className={styles.actions}>
          {/* IMPORTANT
            Do not change the id migrate-pricing-info-modal it is being used
            to show the pop-up with the list of plans.
           */}
          <ButtonLink className={styles.upgradeButtonLink} id="migrate-pricing-info-modal" tertiary>
            Show me the plans
          </ButtonLink>
          <Button className={styles.upgradeButtonLink} onClick={openZendesk}>
            Contact Us
          </Button>
        </div>
      </div>
    </div>
  );
};

export default hot(Subscriptions);
