import { hot } from 'react-hot-loader/root';

import React from 'react';

import { ButtonLink } from '@unbounce/ui-components';

import styles from './PageFooter.css';

interface FooterButtonProps {
  label: string;
  href: string;
  remote?: boolean;
  type: string;
  data: [string, string];
  className: string;
  onClick: string;
}

const parseDataAttr = ([dataType, dataVal]: [string, string]) => ({
  [`data-${dataType}`]: dataVal
});

const FooterButton = ({
  label = '',
  href = '',
  data,
  type = '',
  className,
  onClick,
  ...rest
}: FooterButtonProps) => (
  <ButtonLink
    remove={type === 'remove'}
    secondary={type === 'secondary'}
    onClick={() => {
      if (onClick) window.eval(onClick);
    }}
    {...{ className, href, ...(data && parseDataAttr(data)), ...rest }}
  >
    {label}
  </ButtonLink>
);

const PageFooter = ({ buttons }: { buttons: FooterButtonProps[] }) => (
  <footer className={styles.footer}>{buttons.map(FooterButton)}</footer>
);

export default hot(PageFooter);
