import { hot } from 'react-hot-loader/root';

import React, { useEffect, useState } from "react";

import HtmlUserList from "./HtmlUserList";
import UsersContent from "./UsersContent";
import UsersHeader from "./UsersHeader";
import UsersSubheader from "./UsersSubheader";

export interface UserData {
  name: string;
  email: string;
  avatarUrl: string;
  role: string;
}

interface UsersProps {
  activeUser: {
    label: string;
    href: string;
    count: string;
  };
  userCount: string;
  newInvitationPath: string;
  query: string;
  pendingInvitation: {
    label: string;
    href: string;
    count: string;
  };
  activeUserList: UserData[];
  pendingUserList: UserData[];
  htmlUserList: string;
  noUsersMessage: string;
  pagination: string;
  messageBar?: string;
  featureFlag: boolean;
}

const Users = ({
  activeUser,
  userCount,
  newInvitationPath,
  query,
  pendingInvitation,
  activeUserList,
  pendingUserList,
  htmlUserList,
  noUsersMessage,
  pagination,
  messageBar,
  featureFlag
}: UsersProps) => {
  const [isActive, setIsActive] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [listUsers, setListUser] = useState([...pendingUserList, ...activeUserList]);

  useEffect(() => {
    const urlStr = window.location.href;

    if (urlStr.includes("filter")) {
      urlStr.includes(activeUser.href)
        ? setClassname({ active: true, pending: false })
        : setClassname({ active: false, pending: true });
    }

    urlStr.includes("search")
      ? setListUser(activeUserList)
      : getUserList();
  });

  const setClassname = ({
    active,
    pending
  }: {
    active: boolean;
    pending: boolean;
  }) => {
    setIsActive(active);
    setIsPending(pending);
  };

  const getUserList = () => {
    if (isActive && !isPending) {
      setListUser(activeUserList)
    } else if (!isActive && isPending) {
      setListUser(pendingUserList)
    }
  }

  return (
    <>
      <UsersHeader {...{ userCount, query, newInvitationPath }} />

      {/* TEMP messageBar will be remove after implementing the filters at PL-964 */}
      { !messageBar && <UsersSubheader
        {...{
          activeUser,
          pendingInvitation,
          isActive,
          isPending
        }}
      />}

      {
        featureFlag
          ? <UsersContent {...{
              listUsers,
              noUsersMessage,
              messageBar
            }}
          />
          : <HtmlUserList {...{
              listUsers,
              htmlUserList,
              noUsersMessage,
              messageBar
            }}
          />
      }
      <div dangerouslySetInnerHTML={{ __html: pagination }} />
    </>
  );
};

export default hot(Users);
