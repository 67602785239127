import { Colors } from '@unbounce/ui-components';

export default {
  Alto: `var(${'--' + Colors.Alto})`,
  Amaranth: `var(${'--' + Colors.Amaranth})`,
  Crow: `var(${'--' + Colors.Crow})`,
  DodgerBlue: `var(${'--' + Colors.DodgerBlue})`,
  Emperor: `var(${'--' + Colors.Emperor})`,
  Gallery: `var(${'--' + Colors.Gallery})`,
  Gray: `var(${'--' + Colors.Gray})`,
  KleinBlue: `var(${'--' + Colors.KleinBlue})`,
  LinkWater: `var(${'--' + Colors.LinkWater})`,
  MineShaft: `var(${'--' + Colors.MineShaft})`,
  Orange: `var(${'--' + Colors.Orange})`,
  Pampas: `var(${'--' + Colors.Pampas})`,
  RadicalRed: `var(${'--' + Colors.RadicalRed})`,
  Raven: `var(${'--' + Colors.Raven})`,
  Scorpion: `var(${'--' + Colors.Scorpion})`,
  Shamrock: `var(${'--' + Colors.Shamrock})`,
  Silver: `var(${'--' + Colors.Silver})`,
  SilverAlto: `var(${'--' + Colors.SilverAlto})`,
  SilverChalice: `var(${'--' + Colors.SilverChalice})`,
  UnbounceBlue: `var(${'--' + Colors.UnbounceBlue})`,
  White: `var(${'--' + Colors.White})`
};
