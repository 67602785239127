import { Button } from '@unbounce/ui-components';
import React from 'react';
import { CommonProps } from './../Modal';
import { Description, SubDescription, Body, Progress, SplitFooter, MediaBox } from './Common';

export default ({ nextPage, previousPage, page, closeModal }: CommonProps) => (
  <>
    <Progress {...{ page, closeModal }} />
    <Body loading={false} data-testid="headlineSuggestion-enableSmartTrafficPage">
      <Description>Headlines created! Let’s Turn on Smart Traffic.</Description>
      <SubDescription>
        I’ll inspect each and every visitor’s attributes (like their location or device) and send
        them to the page that is most likely to convert. I’ll start optimizing in as few as 50
        visits.
      </SubDescription>
      <MediaBox src="https://blah.random.gq/mi-loop-st-graphic/" />
      <SubDescription>
        Once I’m up and running, you can sit back and relax as you continue your journey to
        achieving new <strong>Super Marketer</strong> powers.
      </SubDescription>
    </Body>
    <SplitFooter>
      <Button onClick={previousPage} secondary data-testid="headlineSuggestion-previous">
        Back
      </Button>
      <Button onClick={nextPage} data-testid="headlineSuggestion-next">
        Turn on Smart Traffic
      </Button>
    </SplitFooter>
  </>
);
