export enum LoopEvent {
  InsightBadgeViewed = 'allPages-InsightBadge-Viewed',
  InsightBadgeClicked = 'allPages-InsightBadge-Clicked',
  InsightBadgeHovered = 'allPages-InsightBadge-Hovered'
}

interface GonWindow {
  gon: {
    env: string;
    account_uuid: string;
    client_uuid: string;
    user_uuid: string;
  };
}

declare const window: GonWindow;

export function trackLoopEvent(
  message: LoopEvent,
  pageUuid: string
): void {
  if (!window.gon) {
    return;
  }

  const { account_uuid, client_uuid, user_uuid, env } = window.gon;

  const url =
    env === 'production'
      ? 'https://5wkk61iynj.execute-api.us-east-1.amazonaws.com/production/handler'
      : 'https://tfa2ysk87f.execute-api.us-east-1.amazonaws.com/integration/handler';

  const payload = {
    message,
    event_datetime: new Date().toISOString(),
    account_uuid,
    client_uuid,
    user_uuid,
    page_uuid: pageUuid
  };

  fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    keepalive: true
  });
}
