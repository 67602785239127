import { Button } from '@unbounce/ui-components';
import React from 'react';
import { letters } from './../helpers/helpers';
import { CommonProps, PreviewProps } from './../Modal';
import { PreviewBox, PreviewZoom } from './../components/PreviewBox';
import {
  Description,
  Divider,
  Previews,
  SubDescription,
  Body,
  Progress,
  SplitFooter
} from './Common';

type PublishProps = CommonProps & { previewProps: PreviewProps };

export default ({
  previousPage,
  nextPage,
  headlines,
  closeModal,
  page,
  previewProps,
  previewProps: { showPreview }
}: PublishProps) => (
  <>
    <Progress {...{ page, closeModal }} />
    <Body loading={false} data-testid="headlineSuggestion-publishPage">
      <Description>It’s go time! All you need to do is publish and I’ll get to work.</Description>
      <SubDescription>
        Once you publish these variants, I'll begin tracking how visitors convert on each page.
        Ready, marketer? Or, should I say <strong>Super Marketer</strong>?
      </SubDescription>
      <Previews>
        <PreviewBox
          variant="A"
          label={headlines.original.headline}
          image={headlines.original.preview_image_url}
          thumbnail={headlines.original.preview_thumbnail_url}
          {...previewProps}
        />
        <Divider />
        {headlines.variants.map(({ headline, preview_image_url, preview_thumbnail_url }, i) => (
          <PreviewBox
            highlight
            variant={letters[i + 1]}
            label={headline}
            image={preview_image_url}
            thumbnail={preview_thumbnail_url}
            key={'preview-' + i}
            {...previewProps}
          />
        ))}
      </Previews>
    </Body>
    <SplitFooter>
      <Button onClick={previousPage} secondary data-testid="headlineSuggestion-previous">
        Back
      </Button>
      <Button onClick={nextPage} data-testid="headlineSuggestion-next">
        Publish Now
      </Button>
    </SplitFooter>
    {showPreview && <PreviewZoom {...previewProps} />}
  </>
);
