import { Colors, DuplicateIcon, UnbounceIcon, Icon } from '@unbounce/ui-components';
import React from 'react';
import styles from './IconDisc.css';

import classnames from 'classnames';

interface IconDiscProps {
  icon: 'UnbounceIcon' | 'DuplicateIcon';
  className: string;
  ghost?: boolean;
  type?: 'default' | 'soft';
  responsive?: boolean;
}

const iconInternals = {
  DuplicateIcon: <DuplicateIcon />,
  UnbounceIcon: <UnbounceIcon />
} as { [arg: string]: any };

const IconDisc = ({
  icon,
  className,
  ghost = false,
  type = 'default',
  responsive
}: IconDiscProps) => (
  <div
    className={classnames([
      styles.disc,
      ghost ? styles.ghost : null,
      className ? className : null,
      type ? styles[type] : null,
      responsive ? styles.responsive : null
    ])}
  >
    <Icon size="35px" color={ghost ? Colors.UnbounceBlue : Colors.White}>
      {iconInternals[icon]}
    </Icon>
  </div>
);

export default IconDisc;
