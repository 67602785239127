import { Colors, CheckmarkIcon, ErrorIcon, WarningIcon, Icon } from '@unbounce/ui-components';
import React, { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import classnames from 'classnames';

import { createPortalLanding, removePortalLanding } from './../helpers/portal-helpers';

import styles from './Flash.css';

export type flashTypes = 'notice' | 'info' | 'alert' | 'error' | 'pending';

const getIcon = (icon: flashTypes) => {
  if (icon === 'notice' || icon === 'info') {
    return (
      <Icon color={Colors.Shamrock}>
        <CheckmarkIcon />
      </Icon>
    );
  }
  if (icon === 'error') {
    return (
      <Icon color={Colors.RadicalRed}>
        <ErrorIcon />
      </Icon>
    );
  }
  return (
    <Icon color={Colors.Orange}>
      <WarningIcon />
    </Icon>
  );
};

interface FlashProps {
  type: flashTypes;
  duration?: number;
  delay?: number;
  children: ReactNode;
}

const Flash = ({ type, duration, delay, children }: FlashProps) => {
  const [hide, setHide] = useState(false);
  const [hover, setHover] = useState(false);
  const [delayHide, setDelayHide] = useState(false);
  const [init, setInit] = useState(false);

  const portalNode = createPortalLanding('flash');

  const hideItem = () => {
    setHide(true);
  };

  const handleMouseOver = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setDelayHide(false);
  };

  useEffect(() => {
    if (!init) {
      setHide(false);
    }

    setInit(true);

    return () => {
      setHide(false);
      removePortalLanding('flash');
    };
  });

  return ReactDOM.createPortal(
    <div
      className={classnames([
        styles.flash,
        styles[type],
        hide ? styles.hide : null,
        hover ? styles.hover : null,
        delayHide ? styles.delayHide : null
      ])}
      style={{
        animationDuration: duration ? `${duration / 1000}s` : undefined,
        animationDelay: delay ? `${delay / 1000}s` : undefined
      }}
      onClick={hideItem}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {getIcon(type)}
      {children}
    </div>,
    portalNode
  );
};

export default Flash;
