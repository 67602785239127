import React, { ReactNode, useEffect } from 'react';
import ReactModal from 'react-modal';

import { createPortalLanding } from './../helpers/portal-helpers';
import FullScreenLoader from './FullScreenLoader';
import styles from './Modal.css';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';

const overlayStyle = {
  overlay: {
    zIndex: 9999,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

interface ModalProps {
  title: string;
  modalIsOpen: boolean;
  children: ReactNode;
  handleCloseModal: () => void;

  loading?: boolean;
  mediaContent?: string;
  footer?: ReactNode;
}

const Modal = ({
  modalIsOpen,
  handleCloseModal,
  loading,
  title,
  children = <div />,
  footer
}: ModalProps) => {
  useEffect(() => {
    createPortalLanding('modal');
    ReactModal.setAppElement('[data-portal="modal"]');
  });

  return (
    <>
      {loading && <FullScreenLoader />}
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        className={styles.modal}
        style={overlayStyle}
      >
        <ModalHeader
          title={title}
          handleCloseModal={() => {
            handleCloseModal();
          }}
        />
        <div className={styles.content}>
          {children}
          {footer ? <ModalFooter>{footer}</ModalFooter> : null}
        </div>
      </ReactModal>
    </>
  );
};

export default Modal;
