import React, { ReactNode } from "react";

import classnames from "classnames";

import styles from "./Subheader.css";

interface SubheaderProps {
  className: string;
  children: ReactNode;
}

const Subheader = ({ className, children }: SubheaderProps) => (
  <div className={classnames([styles.subheader, className])}>{children}</div>
);

export default Subheader;
