import React from "react";
import styles from "./Users.css";

interface HtmlUserListProps {
  listUsers: any[];
  htmlUserList: string;
  noUsersMessage: string;
  messageBar?: string;
}

const HtmlUserList = ({
  listUsers,
  htmlUserList,
  noUsersMessage,
  messageBar
}: HtmlUserListProps) => (
  <>
    {/* messageBar is only for Admin/User view it will be replaced with the filter PL-964 */}
    { messageBar && <div dangerouslySetInnerHTML={{ __html: messageBar }} />}

    <section className={ styles.contentScrollable }>
      { listUsers.length
        ? <ul className="item-list"
            dangerouslySetInnerHTML={{ __html: htmlUserList }}
          />
        : <div className="empty-list-with-actions"
            dangerouslySetInnerHTML={{ __html: noUsersMessage }} />
      }
    </section>
  </>
);

export default HtmlUserList;