import { hot } from 'react-hot-loader/root';
import React, { useState } from 'react';

import Header from './Header';
import HeaderSearch from './HeaderSearch';
import Subheader from './Subheader';
import EntriNoDomainsView from '../../Views/Domains/EntriNoDomainsView';
import EntriDomainInputModal from '../../Views/Domains/EntriDomainInputModal';

import { Button, ButtonLink } from '@unbounce/ui-components';

import styles from './Domains.css';

declare global {
  interface Window {
    entri: any;
    config: any;
  }
}

interface DomainsProps {
  title: string;
  query: string;
  newDomainPath: string;
  reachedLimit: boolean;
  toggle: string;
  emptyState: boolean;
  entriExperimentEnabled: boolean;
  rootDomainPlan: boolean;
}

const urlSearchParam = (() => {
  const queryPairs = window.location.search.replace(/^\?/, '').split('&');
  const searchPair = queryPairs.find(str => str.startsWith('search='));

  return searchPair?.split('=')[1];
})();

const Domains = ({
  title,
  query,
  newDomainPath,
  reachedLimit,
  toggle,
  emptyState,
  entriExperimentEnabled,
  rootDomainPlan
}: DomainsProps) => {
  const [showEntriDomainInput, setShowEntriDomainInput] = useState(false);
  const entriInputModalFragment = showEntriDomainInput ? (
    <EntriDomainInputModal onClose={() => setShowEntriDomainInput(false)} />
  ) : null;

  if (entriExperimentEnabled && emptyState && !urlSearchParam) {
    return (
      <React.Fragment>
        <EntriNoDomainsView
          newDomainPath={newDomainPath}
          onClickConnectDomain={() => setShowEntriDomainInput(true)}
        />
        {entriInputModalFragment}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Header
        title={
          <React.Fragment>
            {title}
            {query ? <strong> {query}</strong> : ''}
          </React.Fragment>
        }
      >
        {reachedLimit && !rootDomainPlan ? (
          <Button
            data-testid="add-new-domain"
            onClick={() => {
              window.dispatchEvent(
                new CustomEvent('UPDATE_UI.app-chrome.PROMPT_UPGRADE', {
                  detail: {
                    pluralFeatureName: 'custom domains'
                  }
                })
              );
            }}
          >
            Add a Domain
          </Button>
        ) : entriExperimentEnabled ? (
          <>
            <ButtonLink
              href={`${newDomainPath}?domain_type=wordpress`}
              data-testid="add-new-wordpress-domain"
              data-remote="true"
              className="js-overlay-link"
              secondary={true}
              data-kmq={`["record", "CreateNewWordpressDomain.ButtonClicked", {"location": "app.domains"}]`}
              onClick={(ev) => {
                ev.preventDefault();
                window.dispatchEvent(
                  new CustomEvent('UnbounceUITrackingEvent', {
                    detail: {
                      event_type: 'domains_connect_wp_domain_click',
                      workflow: 'domains'
                    }
                  })
                );
              }}
            >
              Connect a Wordpress Domain
            </ButtonLink>
            <Button onClick={() => setShowEntriDomainInput(true)}>Connect a Custom Domain</Button>
          </>
        ) : (
          <ButtonLink
            href={newDomainPath}
            data-testid="add-new-domain"
            data-remote="true"
            className="js-overlay-link"
            data-kmq={`["record", "CreateNew.ButtonClicked", {"location": "app.domains"}]`}
          >
            Add a Domain
          </ButtonLink>
        )}
      </Header>
      <Subheader className={styles.subheader}>
        <div dangerouslySetInnerHTML={{ __html: toggle }} />
        <HeaderSearch urlSearchParam={urlSearchParam} styles={styles.searchbar} />
      </Subheader>

      {entriInputModalFragment}
    </React.Fragment>
  );
};

export default hot(Domains);
