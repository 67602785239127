import React, { ReactNode } from "react";

import classnames from 'classnames';

import styles from "./Header.css";


interface HeaderProps {
  title: string | ReactNode;
  children: ReactNode;
  stacking?: boolean;
}

const Header = ({ children, title, stacking }: HeaderProps) => (
  <header className={classnames([styles.header, stacking && styles.stacking])}>
    <h2 className={styles.title}>{title}</h2>
    {children}
  </header>
);

export default Header;
