import React, { useState } from 'react';

import ModalFooter from './../../Modal/ModalFooter';

import { Button } from '@unbounce/ui-components';

import styles from './Add.css';
import { incrementCounter } from './helpers';

import Loader from './Loader';

interface AddProps {
  addMessage: (e: string) => void;
  closeModal: () => void;
  addPagePackUrl: string;
  token: string;
}

const Add = ({ addMessage, closeModal, addPagePackUrl, token }: AddProps) => {
  const [loading, setLoading] = useState(false);

  const finalize = (response: string) => {
    setLoading(false);
    addMessage(response);
    incrementCounter(50);
    closeModal();
  };

  const addProceed = () => {
    setLoading(true);
    fetch(addPagePackUrl, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'text/plain',
        'X-CSRF-Token': token
      })
    }).then((res) => {
      res.status === 200 ? finalize('success') : finalize('fail');
    });
  };

  return (
    <>
      <main className={styles.content}>
        <h5 className={styles.title}>Confirm, Add My Page Pack</h5>
        <p className={styles.description}>
          Each pack contains <strong>50 published pages</strong> for <strong>$50/month</strong>.
          Once you confirm adding a Page Pack, your account will be charged a prorated amount.
        </p>
        <p className={styles.description}>
          If you choose to remove a Page Pack before your next renewal date, we will refund the
          unused portion as credit on your next invoice.
        </p>
      </main>
      <ModalFooter>
        <Button secondary onClick={closeModal}>
          Cancel
        </Button>
        <Button onClick={addProceed}>Proceed with Page Pack</Button>
      </ModalFooter>
      {loading ? <Loader /> : null}
    </>
  );
};

export default Add;
