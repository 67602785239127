import React from 'react';

import { ChipTypes, UserListItem } from '@unbounce/ui-components';
import { UserData } from './Users';

import styles from './Users.css';

const RoleMap = (role: string) => {
  switch (role) {
    case 'administrator':
      return ChipTypes.Administrator;
    case 'author':
      return ChipTypes.Author;
    case 'owner':
      return ChipTypes.Owner;
    case 'viewer':
      return ChipTypes.Viewer;
    default:
      return ChipTypes.Pending;
  }
};

interface UsersContentProps {
  listUsers: UserData[];
  noUsersMessage: string;
  messageBar?: string;
}

const UsersContent = ({ listUsers, noUsersMessage, messageBar }: UsersContentProps) => {
  return (
    <>
      {/* messageBar is only for Admin/User view it will be replaced with the filter PL-964 */}
      {messageBar && <div dangerouslySetInnerHTML={{ __html: messageBar }} />}

      <section className={styles.contentScrollable}>
        {listUsers.length ? (
          listUsers.map((user, index) => (
            <UserListItem
              name={user.name}
              email={user.email}
              image={user.avatarUrl}
              types={[RoleMap(user.role)]}
              className={styles.main}
              key={user.email + index}
              data-testid="user-list-item"
            />
          ))
        ) : (
          <div
            className="empty-list-with-actions"
            dangerouslySetInnerHTML={{ __html: noUsersMessage }}
          />
        )}
      </section>
    </>
  );
};

export default UsersContent;
