import React from 'react';
import styles from './Loader.css';
import { Loader } from '@unbounce/ui-components';

const PagePackLoader = () => (
  <div className={styles.loader}>
    <Loader
      title="Adding a Page Pack..."
      description="Once complete you will see a increase of 50 pages in your progress bar of remaining pages."
    />
  </div>
);

export default PagePackLoader;
