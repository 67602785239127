import { hot } from 'react-hot-loader/root';
import classnames from "classnames";
import React from "react";
import styles from "./Counter.css";

import UpgradeButton from "./UpgradeButton";

interface CounterProps {
  packAvailable: number;
  pagesRemaining: number;
  upgradeButton: {
    subscriptionLink: string;
    addPagePackUrl: string;
    csrfToken: string;
    upgradeButtonLink: string;
    displayModal: string;
  };
  badgeColor: string;
  infoMessage: string;
}

const Counter = ({
  packAvailable,
  pagesRemaining,
  upgradeButton,
  badgeColor,
  infoMessage
}: CounterProps) => (
  <>
    <div className={styles.wrapper}>
      <div
        className={classnames([styles[badgeColor], styles.badge, "usage"])}
      />
      <label className="value">{pagesRemaining} remaining</label>
      <div className={styles.upgradeButton}>
        {packAvailable ? (
          <UpgradeButton
            subscriptionLink={upgradeButton.subscriptionLink}
            addPagePackUrl={upgradeButton.addPagePackUrl}
            csrfToken={upgradeButton.csrfToken}
            upgradeButtonLink={upgradeButton.upgradeButtonLink}
            displayModal={upgradeButton.displayModal}
          />
        ) : null}
      </div>
    </div>
    <div className={styles.info}>{infoMessage}</div>
  </>
);

export default hot(Counter);
