import React, { useState } from 'react';
import { GraphQLClient } from 'graphql-request';
import PublishingModalContent from './PublishingModalContent';
import { sentryCaptureException } from './../helpers/sentry';

const csrfToken = document?.querySelector('meta[name=csrf-token]')?.getAttribute('content');
const csrfParam = document?.querySelector('meta[name=csrf-param]')?.getAttribute('content');

const publishFormData = new FormData();
publishFormData.append('_method', 'put');
publishFormData.append(csrfParam ? csrfParam : '', csrfToken ? csrfToken : '');

const endpoint: { [arg: string]: string } = {
  production: 'https://gateway.unbounce.com/graphql',
  integration: 'https://gateway.unbounce-test.com/graphql',
  development: '/graphql'
};
const env = window.gon.env;

export default ({
  uuids: { page_uuid, suggestion_uuid },
  closeModal,
  publish_page_path
}: {
  uuids: { page_uuid: string; suggestion_uuid: string };
  closeModal: () => void;
  publish_page_path: string;
}) => {
  const [loadDone, setLoadDone] = useState(false);
  const [error, setError] = useState(false);
  console.log(loadDone, 'loadDone');

  const ACCEPT_SUGGESTION = `
    mutation AcceptSuggestion($pageUuid: String!, $suggestionUuid: String!) {
      acceptSuggestion(input: { pageUuid: $pageUuid, suggestionUuid: $suggestionUuid }) {
        errors
      }
    }
  `;

  const initAccept = () => {
    console.log('Get Token');
    return fetch('/users/token?type=jwt', {
      method: 'POST',
      headers: {
        'x-csrf-token': csrfToken ? csrfToken : ''
      }
    })
      .then((data: any) => data.json())
      .then(({ token }: any) => {
        console.log('Init GraphQL');
        const client = new GraphQLClient(endpoint[env ? env : 'integration'], {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-csrf-token': csrfToken ? csrfToken : ''
          }
        });

        client
          .request(ACCEPT_SUGGESTION, {
            pageUuid: page_uuid,
            suggestionUuid: suggestion_uuid
          })
          .then((data: any) => {
            console.log('acceptSuggestion ok');
            console.log(data);
            console.log('Init Publish');

            const errors = data.acceptSuggestion.errors;
            if (errors.length > 0) {
              throw new Error(errors.join('; '));
            }

            fetch(publish_page_path, { method: 'post', body: publishFormData })
              .then((result) => {
                console.log('Publish Init Ok');
                console.log(result);
                setLoadDone(true);
              })
              .catch((result) => {
                console.error('Not ok');
                console.error(result);
                setLoadDone(true);
                setError(true);
              });
          })
          .catch((result) => {
            console.error('acceptSuggestion Fail');
            console.error(result);
            sentryCaptureException(result);
            setLoadDone(true);
            setError(true);
          });
      });
  };

  csrfToken && !loadDone && initAccept();

  return (
    <>
      <PublishingModalContent {...{ loadDone, error, closeModal }} />
    </>
  );
};
