import React from 'react';

type Props = {
  newDomainPath: string;
  onClickConnectDomain: () => void;
};

const EntriNoDomainsView = ({ newDomainPath, onClickConnectDomain }: Props) => {
  const newWordpressDomainPath = `${newDomainPath}?domain_type=wordpress`;
  const newManualDomainPath = `${newDomainPath}?domain_type=manual_domain`;

  return (
    <div className="empty-list-with-actions">
      <div className="entri-empty-domain">
        <h2>Connect your domain</h2>

        <p className="blank-slate-desc">
          You can publish landing pages with custom URLs once you've connected a domain.
        </p>

        <p>
          <a
            className="js-overlay-link btn large secondary iframe btn-margin"
            data-remote="true"
            id="entri_wordpress_empty"
            href={newWordpressDomainPath}
          >
            Connect a Wordpress Domain
          </a>
          <a className="btn large blue" id="entri_custom_empty" onClick={onClickConnectDomain}>
            Connect a Domain
          </a>
        </p>

        <div className="entri-docs-links">
          <a
            href="https://documentation.unbounce.com/hc/en-us/articles/360001292583"
            rel="noreferrer"
            target="_blank"
          >
            How to Connect your Domain
          </a>

          <a
            className="js-overlay-link"
            data-remote="true"
            href={newManualDomainPath}
          >
            Manual Domain Connection
          </a>
          <a href="https://try.unbounce.com/purchase-domain" rel="noreferrer" target="_blank">
            Purchase a Domain
          </a>
        </div>
      </div>
    </div>
  );
};

export default EntriNoDomainsView;
