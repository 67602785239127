import { Loader } from '@unbounce/ui-components';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';

import RetryButton from './RetryButton';
import StatusLabel, { type Props as StatusLabelProps } from './StatusLabel';

import styles from './DomainStatus.css';

interface DomainStatusProps {
  button: {
    label: string;
    modal: {
      title: string;
      footer: boolean;
    };
    showModal: boolean;
    actions: {
      doNotShowModal: string;
      retryDomainSetup: string;
    };
    visible: boolean;
  };
  status: StatusLabelProps;
}

const DomainStatus = ({ button, status: initStatus }: DomainStatusProps) => {
  const [loading, setLoading] = useState(false);
  const [showRetry, setShowRetry] = useState(button.visible);
  const [status, setStatus] = useState(initStatus);

  const retryButtonProps = {
    setLoading,
    setStatus,
    setShowRetry,
    ...button
  };

  const statusProps = status ? status : initStatus;

  const LoadingStatus = () => (
    <>
      <Loader />
      <span>Retrying domain setup</span>
    </>
  );

  const LoadedStatus = () => (
    <>
      <StatusLabel {...statusProps} />
      {showRetry ? <RetryButton {...retryButtonProps} /> : null}
    </>
  );

  return (
    <div className={loading ? styles.wrapperLoading : styles.wrapperStatus}>
      {loading ? <LoadingStatus /> : <LoadedStatus />}
    </div>
  );
};

export default hot(DomainStatus);
