import styled from 'styled-components';
import { Colors, AddIcon, Icon } from '@unbounce/ui-components';
import React from 'react';
import colors from './../helpers/colors';
import { PreviewProps } from './../Modal';
import { CloseX } from './../Stages/Common';
import { sentryCaptureMessage } from './../helpers/sentry';

const { Gray, Silver, UnbounceBlue } = colors;

const BoxWrapper = styled.div`
  grid-template-rows: auto 1fr;
  display: grid;
  grid-gap: 4px;
`;

const Label = styled.label<{ highlight?: boolean }>`
  font-size: 1em;
  color: ${({ highlight }) => (highlight ? UnbounceBlue : Gray)};
  font-weight: bold;
`;

const Variant = styled.div``;

const Preview = styled.div`
  border: 1px solid ${Silver};
  border-radius: 3px;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  grid-gap: 4px;
  overflow: hidden;
  margin-bottom: 2.5vh;
  width: 100%;
  height: 19vw;
  min-width: 92px;
  min-height: 140px;
  max-height: 220px;
`;

const ZoomWrap = styled.div`
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  opacity: 0;
  transition: opacity 0.25s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const ZoomBg = styled.div`
  background: ${UnbounceBlue};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 75%;
`;

const ZoomIcon = styled(Icon)`
  z-index: 1;
  width: 64px;
  height: 64px;
`;

const Plus = styled(AddIcon)`
  display: flex;
  margin-left: -24px;
  margin-top: -24px;
  width: 64px;
  height: 64px;
`;

const Zoom = () => (
  <ZoomWrap>
    <ZoomIcon color={Colors.White}>
      <Plus />
    </ZoomIcon>
    <ZoomBg />
  </ZoomWrap>
);

const PreviewImage = styled.div<{ image: string }>`
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  height: 100%;
  grid-column: 1;
  grid-row: 1;
`;

export const PreviewBox = ({
  label,
  image,
  thumbnail,
  highlight,
  variant,
  setBg,
  setShowPreview
}: {
  label: string;
  image: string;
  thumbnail: string;
  highlight?: boolean;
  variant: string;
} & PreviewProps) => (
  <BoxWrapper>
    <Preview
      onClick={() => {
        setBg(image);
        setShowPreview(true);
        sentryCaptureMessage(`Clicked on preview image`);
      }}
    >
      <PreviewImage image={thumbnail} />
      <Zoom />
    </Preview>
    <Label {...{ highlight }}>{label}</Label>
    <Variant>{`Variant ${variant}`}</Variant>
  </BoxWrapper>
);

const PopupPreview = styled.div<{ bg: string }>`
  height: 80vh;
  width: 70vh;
  background-image: url('${({ bg }) => bg}');
  box-shadow: 0 0 40px 5px black;
  border-radius: 10px;
  pointer-events: none;
  background-size: cover;
  background-position: top center;
  position: relative;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
`;

export const PreviewZoom = ({ bg, showPreview, setShowPreview }: PreviewProps) => (
  <Wrapper
    onClick={() => {
      setShowPreview(false);
    }}
    data-testid="preview-zoom-wrapper"
  >
    <PopupPreview {...{ bg, showPreview }} data-testid="preview-zoom-image">
      <CloseX light={true} data-testid="preview-zoom-close" />
    </PopupPreview>
  </Wrapper>
);
