import React from 'react';
import { Button, ButtonLink } from '@unbounce/ui-components';
import styles from './Intro.css';
import IconDisc from './../../Basic/IconDisc';

interface UpgradeProps {
  subscriptionLink: string;
}

const Upgrade = ({ subscriptionLink }: UpgradeProps) => (
  <div className={styles.upgrade}>
    <IconDisc className={styles.disc} icon={'UnbounceIcon'} />
    <h5 className={styles.title}>Upgrade Your Account</h5>
    <p className={styles.description}>
      Get more pages, popups and clients by upgrading your Unbounce subscription.
    </p>
    <ButtonLink className={styles.upgradeButtonLink} href={subscriptionLink}>
      Upgrade your account
    </ButtonLink>
  </div>
);

interface AddProps {
  changePage: (e: string) => void;
}

const Add = ({ changePage }: AddProps) => (
  <div className={styles.add}>
    <IconDisc className={styles.disc} icon={'DuplicateIcon'} ghost />
    <h5 className={styles.title}>Add a Page Pack</h5>
    <p className={styles.description}>
      Each pack contains 50 published pages for $50/month. Heads up: when you add a pack, this
      month’s invoice will be prorated.
    </p>
    <Button
      tertiary
      className={styles.addPackButton}
      onClick={() => {
        changePage('add');
      }}
    >
      Add a Page Pack
    </Button>
  </div>
);

type IntroProps = AddProps & UpgradeProps;

const Intro = ({ subscriptionLink, changePage }: IntroProps) => (
  <div className={styles.content}>
    <Upgrade {...{ subscriptionLink }} />
    <Add {...{ changePage }} />
  </div>
);

export default Intro;
