/**
 * Ad-Hoc Sentry Client for MI Loops 2
 * Experimental feature event tracking. Expected to be removed after experiment completes.
 */
import { BrowserClient, Hub } from '@sentry/browser';

export enum Event {
  INSIGHT_BADGE_CLICKED = 'page_list_insight_badge_clicked'
}

interface ServerContext {
  account_uuid: string;
  client_uuid: string;
  user_uuid: string;
  env: string;
}

const serverContext: ServerContext = (window as any).gon;

const MI_LOOPS_2_DSN = 'https://41536a4760834436b4a8b4622473de75@sentry.io/5181721';

const hub = new Hub(
  new BrowserClient({
    dsn: MI_LOOPS_2_DSN,
    environment: serverContext.env
  })
);

hub.configureScope((scope) => {
  scope.setUser({ id: serverContext.user_uuid });
  scope.setTags({
    accountId: serverContext.account_uuid,
    clientId: serverContext.client_uuid
  });
});

export const sentryCaptureMessageForLoop2 = (message: string) => hub.captureMessage(message);
export const sentryCaptureExceptionForLoop2 = (exception: Error) => hub.captureException(exception);
