import styled from 'styled-components';
import { Modal } from '@unbounce/ui-components';
import React, { Dispatch, SetStateAction, useState } from 'react';
import Publish from './Stages/Publish';
import CreateVariants from './Stages/CreateVariants';
import EnableSmartTraffic from './Stages/EnableSmartTraffic';
import Publishing from './Stages/Publishing';

type Headline = {
  headline: string;
  preview_image_url: string;
  preview_thumbnail_url: string;
  variant_id: number;
};

type Headlines = {
  original: Headline;
  variants: Headline[];
};

export interface CommonProps {
  nextPage: () => void;
  previousPage: () => void;
  page: number;
  closeModal: () => void;
  headlines: Headlines;
}

export interface PreviewProps {
  bg: string;
  setBg: Dispatch<SetStateAction<string>>;
  showPreview: boolean;
  setShowPreview: Dispatch<SetStateAction<boolean>>;
}

interface ModalProps extends CommonProps {
  isOpen: boolean;
  uuids: {
    page_uuid: string;
    suggestion_uuid: string;
  };
  publish_page_path: string;
}

const HeadlineModal = styled(Modal)`
  width: 65vw !important;
  max-width: 800px;
  min-width: 500px;
`;

export default ({
  isOpen,
  page,
  closeModal,
  nextPage,
  headlines,
  previousPage,
  uuids,
  publish_page_path
}: ModalProps) => {
  const [bg, setBg] = useState('');
  const [showPreview, setShowPreview] = useState(false);

  const previewProps: PreviewProps = {
    bg,
    setBg,
    showPreview,
    setShowPreview
  };

  const commonProps: CommonProps = {
    nextPage,
    previousPage,
    page,
    closeModal,
    headlines
  };

  const Pages = [
    <CreateVariants {...{ ...commonProps, previewProps }} />,
    <EnableSmartTraffic {...{ ...commonProps }} />,
    <Publish {...{ previewProps, ...commonProps }} />,
    <Publishing {...{ uuids, closeModal, publish_page_path }} />
  ];

  return (
    <HeadlineModal isOpen={isOpen} data-testid="headline-suggestion-modal">
      {Pages[page]}
    </HeadlineModal>
  );
};
