import styles from "./portal.css";

export const createPortalLanding = (type: string) => {
  let portalNode = document.querySelector(`[data-portal="${type}"]`);
  if (!portalNode) {
    portalNode = document.createElement("div");
    portalNode.setAttribute("data-portal", type);
    portalNode.className = styles.portal;
    document.body.appendChild(portalNode);
  }
  return portalNode;
};

export const removePortalLanding = (type: string) => {
  const portalNode = document.querySelector(`[data-portal="${type}"]`);
  if (portalNode && portalNode.parentNode) {
    portalNode.parentNode.removeChild(portalNode);
  }
};

// Calculate which vertical or horizontal third of the screen the
// originating button is located in
const getGridLocation = (location: number, scale: number): number =>
  Math.floor(location / (scale / 2));

interface PointsProps {
  left: number;
  width: number;
  height: number;
  top: number;
}

const quadrant = ({ left, width, height, top }: PointsProps) => ({
  x: getGridLocation(left + width / 2, window.innerWidth),
  y: getGridLocation(top + height / 2, window.innerHeight)
});

export const calcParentAnchor = (parent: HTMLElement) => {
  const {
    top,
    left,
    bottom,
    right,
    height,
    width
  } = parent.getBoundingClientRect();
  return {
    top,
    left,
    right,
    bottom,
    ...quadrant({ left, width, height, top })
  };
};
