import { Loader } from '@unbounce/ui-components';
import React from 'react';

import styles from './FullScreenLoader.css';

const FullScreenLoader = () => (
  <div className={styles.wrap}>
    <Loader />
  </div>
);

export default FullScreenLoader;
