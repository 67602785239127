import styled from 'styled-components';
import { Button, Banner, Loader, ModalFooter } from '@unbounce/ui-components';
import React from 'react';
import { Body, Description } from './Common';

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 30px;
  text-shadow: 0 0 0 transparent;
`;

interface PublishingModalContent {
  loadDone: boolean;
  error: boolean;
  closeModal: () => void;
}

export default ({ loadDone, error, closeModal }: PublishingModalContent) => {
  const ActiveLoader = () => (
    <Loader
      title="Hold Tight..."
      description="Creating your headline variants and turning on Smart Traffic."
      visible={true}
    />
  );

  return (
    <>
      {error && loadDone && <Banner type="error">An Error Has Occurred</Banner>}
      <Body loading={false} data-testid="headlineSuggestion-publishingPage">
        <Title>🚀Liftoff! Congrats, more conversions are headed your way.</Title>
        <Description>
          One more thing: editing your page or any variants will slow me down. So kick back and
          relax. I’ll let you know once the learning phase is complete and I’m done scanning your
          visitors to learn how they convert on each variant.
        </Description>
        {!loadDone && <ActiveLoader />}
      </Body>
      <ModalFooter>
        <Button
          onClick={() => {
            loadDone && !error && window.location.reload();
            error && closeModal();
          }}
          disabled={!loadDone && !error}
        >
          Got It!
        </Button>
      </ModalFooter>
    </>
  );
};
