/**
 * Sentry Client for MI Loops 1
 * Experimental feature event tracking. Expected to be removed after experiment completes.
 */
import { BrowserClient, Hub } from '@sentry/browser';

interface ServerContext {
  account_uuid: string;
  client_uuid: string;
  user_uuid: string;
  env: string;
}

const serverContext: ServerContext = (window as any).gon;

const client = new BrowserClient({
  dsn: 'https://107dc84602e142b1ab75cad6d5d8157c@sentry.io/2741799',
  environment: serverContext.env
});

const hub = new Hub(client);

hub.configureScope(scope => {
  scope.setUser({ id: serverContext.user_uuid });
  scope.setTags({
    accountId: serverContext.account_uuid,
    clientId: serverContext.client_uuid
  });
});

export const sentryCaptureMessage = (message: string) => hub.captureMessage(message);
export const sentryCaptureException = (exception: any) => hub.captureException(exception);
