import React, { ReactNode, useState } from 'react';
import { hot } from 'react-hot-loader/root';

import styles from './UpgradeButton.css';

import Flash, { flashTypes } from './../../Basic/Flash';
import Modal from './../../Modal/Modal';
import Add from './../PagePackModal/Add';
import Intro from './../PagePackModal/Intro';

interface UpgradeButtonProps {
  subscriptionLink: string;
  addPagePackUrl: string;
  csrfToken: string;
  upgradeButtonLink: string;
  displayModal: string;
}

interface FlashDetailsStateProps {
  type: flashTypes;
  description: string;
}

interface FlashDetailsProps {
  [key: string]: FlashDetailsStateProps;
}

const flashDetails: FlashDetailsProps = {
  pending: {
    type: 'pending',
    description: 'Currently processing'
  },
  success: {
    type: 'info',
    description:
      'Success - you did it! A pack of 50 extra pages has been added to your Unbounce account.'
  },
  fail: {
    type: 'error',
    description:
      'Oh no, something went wrong! The upgrade was not processed. Please try again in a few minutes or contact support for help.'
  }
};

interface PagesProps {
  page: ReactNode;
  title: string;
}

const UpgradeButton = ({
  subscriptionLink,
  addPagePackUrl,
  csrfToken,
  upgradeButtonLink,
  displayModal
}: UpgradeButtonProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [page, setPage] = useState('intro');
  const [upgrade, setUpgrade] = useState('pending');

  const changePage = (currPage: string) => setPage(currPage);
  const addMessage = (currResponse: string) => setUpgrade(currResponse);

  const pages = {
    intro: {
      page: <Intro changePage={changePage} subscriptionLink={subscriptionLink} />,
      title: 'Increase Your Publishing Limit'
    },
    add: {
      page: (
        <Add
          closeModal={() => setModalIsOpen(false)}
          addPagePackUrl={addPagePackUrl}
          token={csrfToken}
          addMessage={addMessage}
        />
      ),
      title: 'Add a Page Pack'
    }
  } as { [key: string]: PagesProps };

  const openModal = () => {
    setModalIsOpen(true);
    setPage('intro');
    setUpgrade('pending');
  };

  const upgradeButtons = {
    link: (
      <a href={upgradeButtonLink} className={styles.link}>
        Upgrade
      </a>
    ),
    modal: (
      <a onClick={openModal} className={styles.link}>
        Upgrade
      </a>
    )
  };

  const flashType = flashDetails[upgrade].type;
  const flashDescription = flashDetails[upgrade].description;

  return (
    <>
      {displayModal ? upgradeButtons.modal : upgradeButtons.link}
      <Modal
        title={pages[page].title}
        modalIsOpen={modalIsOpen}
        handleCloseModal={() => setModalIsOpen(false)}
      >
        {pages[page].page}
      </Modal>
      {upgrade !== 'pending' ? <Flash type={flashType}>{flashDescription}</Flash> : null}
    </>
  );
};

export default hot(UpgradeButton);
