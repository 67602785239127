import React from "react";
import styles from "./DoNotShow.css";

interface DoNotShowProps {
  change: () => void;
  active: boolean;
}

const DoNotShow = ({ change, active }: DoNotShowProps) => (
  <div className={styles.wrapper}>
    <input
      className={styles.input}
      type="checkbox"
      checked={active}
      onChange={change}
    />
    <label className={styles.label} onClick={change}>
      Don't show me this again
    </label>
  </div>
);

export default DoNotShow;
