import React, { FormEvent, useState } from 'react';

import { SearchBar } from '@unbounce/ui-components';

interface HeaderSearchProps {
  urlSearchParam?: string;
  styles: string;
}

const HeaderSearch = ({ urlSearchParam = '', styles }: HeaderSearchProps) => {
  const [search, setSearch] = useState(urlSearchParam );

  const handleChange = ({ target }: FormEvent<HTMLInputElement>) => {
    const { value } = target as HTMLInputElement;
    setSearch(value);
  };

  const handleSearch = (searchValue = search) => {
    const url = new URL(window.location.href);

    url.search = '';

    if (searchValue.trim().length) {
      url.searchParams.set('utf8', '✓');
      url.searchParams.set('search', search);
    }

    window.location.href = url.href;
  };

  const handleClear = () => {
    setSearch("");

    if (Boolean(urlSearchParam)) {
      handleSearch("");
    }
  };

  return (
    <SearchBar
      mini
      clearAfterSubmit={false}
      placeholder="Search domains..."
      name="search"
      value={search}
      onChange={handleChange}
      onClear={handleClear}
      onSubmit={() => handleSearch()}
      className={styles}
    />
  );
};

export default HeaderSearch;
