import styled from 'styled-components';
import { ModalFooter, Button } from '@unbounce/ui-components';
import React from 'react';
import { PreviewBox, PreviewZoom } from './../components/PreviewBox';
import { letters } from './../helpers/helpers';
import { CommonProps, PreviewProps } from './../Modal';
import { Description, Divider, Previews, SubDescription, Body, Progress } from './Common';

type CreateVariantsProps = CommonProps & { previewProps: PreviewProps };

const HR = styled.div`
  border: 0 none;
  display: block;
  border-bottom: 1px solid #cfcfcf;
  width: 100%;
  margin-bottom: 10px;
`;

const PaddedDescription = styled(Description)`
  padding-bottom: 20px;
`;

export default ({
  page,
  closeModal,
  headlines,
  headlines: { original },
  nextPage,
  previewProps,
  previewProps: { showPreview }
}: CreateVariantsProps) => (
  <>
    <Progress {...{ page, closeModal }} />
    <Body loading={false} data-testid="headlineSuggestion-createVariantsPage">
      <Description>
        My name is Smart Traffic. I’m here to turn you into the <strong>Super Marketer</strong> of
        your dreams. Prepare for 👌conversion-nirvana👌
      </Description>
      <SubDescription>
        To start, here are two new landing page variants built for you; each new page variant is
        exactly the same as your original, except for a new headline. Take a look:
      </SubDescription>
      <Previews>
        <PreviewBox
          variant="A"
          label={original.headline}
          image={original.preview_image_url}
          thumbnail={original.preview_thumbnail_url}
          {...previewProps}
        />
        <Divider />
        {headlines.variants.map(({ headline, preview_image_url, preview_thumbnail_url }, i) => (
          <PreviewBox
            variant={letters[i + 1]}
            label={headline}
            image={preview_image_url}
            thumbnail={preview_thumbnail_url}
            key={'preview-' + i}
            highlight
            {...previewProps}
          />
        ))}
      </Previews>
      <HR />
      <SubDescription>
        By testing out different page variants, I can learn which visitors will convert best on a
        specific page. Then, I’ll route individual visitors to the page where they’re most likely to
        convert.
      </SubDescription>
      <PaddedDescription>
        I turn marketers like yourself into <strong>Super Marketers</strong>, achieving up to 20%
        more conversions. Wanna give me a whirl?
      </PaddedDescription>
    </Body>
    <ModalFooter>
      <Button onClick={nextPage} data-testid="headlineSuggestion-next">
        Let's Do This
      </Button>
    </ModalFooter>
    {showPreview && <PreviewZoom {...previewProps} />}
  </>
);
