import styled from 'styled-components';
import React from 'react';
import { Colors, Icon, CheckmarkIcon } from '@unbounce/ui-components';
import colors from '../helpers/colors';
import styles from './ProgressNodeMeter.css';
import classnames from 'classnames';

const { Alto, Gray, UnbounceBlue, White, Shamrock } = colors;

interface ProgressNodeMeterProps {
  /** Which node you're currently on */
  current?: number;
  /** How many nodes on the range */
  max?: number;
  /** Nodes before the current remain filled in */
  progressive?: boolean;
  /** The nodes are numbered */
  numbered?: boolean;
  /** The completed nodes have a check */
  checked?: boolean;
  /** Labels that appear below the nodes */
  labels?: string[];
  /** Size of the nodes */
  nodeRadius?: number;
}

const Wrapper = styled.div<{ labelSpace?: boolean }>`
  padding-bottom: ${({ labelSpace }) => (labelSpace ? '25px' : 0)};
`;

const Node = styled.div<{ nodeRadius: number }>`
  width: ${({ nodeRadius }) => nodeRadius + 'px'};
  height: ${({ nodeRadius }) => nodeRadius + 'px'};
  border-radius: ${({ nodeRadius }) => nodeRadius + 'px'};
  border: 2px solid ${UnbounceBlue};
  color: ${UnbounceBlue};
  background: ${White};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

const CheckNode = styled(Node)<{ nodeRadius: number }>`
  width: ${({ nodeRadius }) => nodeRadius + 'px'};
  height: ${({ nodeRadius }) => nodeRadius + 'px'};
  border: 2px solid ${Shamrock};
  background: ${Shamrock};
`;

const Line = styled.div<{ active: boolean }>`
  background: ${({ active }) => (active ? Shamrock : Alto)};
  height: 2px;
  width: 100%;
`;

const Label = styled.label<{ current?: boolean }>`
  color: ${({ current }) => (current ? UnbounceBlue : Gray)};
`;

const ActiveNode = styled(Node)<{ nodeRadius: number }>`
  color: ${White};
  background: ${UnbounceBlue};
`;

const Nodes = ({
  max,
  current,
  numbered,
  labels,
  checked,
  nodeRadius
}: {
  max: number;
  current: number;
  numbered: boolean;
  checked: boolean;
  labels?: string[];
  nodeRadius: number;
}) => {
  const nodeCollection = [];
  for (let i = 0; i < max; i++) {
    const currentNode = current === i;

    const CurrentNode = () => {
      if (i === current) {
        return (
          <ActiveNode
            key={'node-' + i}
            nodeRadius={nodeRadius}
            className={classnames([styles.node, currentNode ? styles.currentNode : null])}
            data-testid={'progress-node-meter-node'}
          >
            {numbered && `${i + 1}`}
          </ActiveNode>
        );
      }
      if (checked && i < current) {
        return (
          <CheckNode
            key={'node-' + i}
            nodeRadius={nodeRadius}
            className={styles.checkNode}
            data-testid={'progress-node-meter-node'}
          >
            <Icon color={Colors.White}>
              <CheckmarkIcon />
            </Icon>
          </CheckNode>
        );
      }
      return (
        <Node
          key={'node-' + i}
          nodeRadius={nodeRadius}
          className={classnames([styles.node, currentNode ? styles.currentNode : null])}
          data-testid={'progress-node-meter-node'}
        >
          {numbered && `${i + 1}`}
        </Node>
      );
    };

    nodeCollection.push(
      <div className={styles.nodeAndLabelWrapper} data-testid="progress-node-meter-node-wrapper">
        <CurrentNode />
        {labels && (
          <Label
            current={current === i}
            className={styles.label}
            data-testid={'progress-node-meter-label'}
          >
            {labels[i]}
          </Label>
        )}
      </div>
    );
  }
  return (
    <div className={styles.nodeWrapper} data-testid="progress-node-meter-nodes-wrapper">
      {nodeCollection}
    </div>
  );
};

const Lines = ({
  max,
  current,
  progressive
}: {
  max: number;
  current: number;
  progressive: boolean;
}) => {
  const lineCollection = [];
  for (let i = 0; i < max - 1; i++) {
    const active = progressive && i < current - 1;
    lineCollection.push(<Line active={active} key={'line-' + i} />);
  }
  return <div className={styles.lineRange}>{lineCollection}</div>;
};

export default ({
  labels,
  max = (labels && labels.length) || 3,
  current = 0,
  progressive = false,
  numbered = false,
  checked = false,
  nodeRadius = 30
}: ProgressNodeMeterProps) => {
  const nodeProps = {
    ...(checked ? { checked: true } : { checked: false }),
    ...(progressive ? { progressive: true } : { progressive: false }),
    ...(numbered ? { numbered: true } : { numbered: false }),
    max,
    current,
    labels,
    nodeRadius: nodeRadius ? nodeRadius : 30
  };

  return (
    <Wrapper
      labelSpace={labels && labels.length > 0}
      data-testid="progress-node-meter-wrapper"
      className={styles.wrapper}
    >
      {Nodes({ ...nodeProps })}
      {Lines({ max, current, progressive })}
    </Wrapper>
  );
};
