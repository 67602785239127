const setValues = (
  additional: number,
  counter: HTMLElement,
  usage: HTMLElement,
  value: HTMLElement
) => {
  const width = usage.style.width;
  const usageAmount = parseInt(width ? width : "0px", 10);

  if (usageAmount > 20) usage.style.width = `${usageAmount - 20}%`;

  if (usage.classList.contains("nearly-full")) {
    usage.classList.remove("nearly-full");
  }

  if (usage.classList.contains("full")) {
    usage.classList.remove("full");
    usage.classList.add("nearly-full");
  }

  value.innerText = `${parseInt(value.innerText, 10) + additional} remaining`;
};

const queryElements = (additional: number, counter: HTMLElement) => {
  const usage = counter.querySelector(".usage") as HTMLElement;
  const value = counter.querySelector(".value") as HTMLElement;
  if (usage && value) setValues(additional, counter, usage, value);
};

export const incrementCounter = (additional: number) => {
  const counter = document.querySelector(
    ".published-pages-meter-container"
  ) as HTMLElement;

  if (counter) {
    queryElements(additional, counter);
  }
};
