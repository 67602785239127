import {
  CheckmarkIcon,
  Colors,
  ErrorIcon,
  Icon,
  Tooltip,
  WarningIcon
} from '@unbounce/ui-components';
import classnames from 'classnames';
import React, { ReactNode } from 'react';

import styles from './StatusLabel.css';

export interface Props {
  label: string;
  message: string;
  showContactCs: boolean;
  type: 'error' | 'warn' | 'green';
}

const icons = {
  error: (
    <Icon className={styles.icon} color={Colors.RadicalRed}>
      <ErrorIcon />
    </Icon>
  ),
  warn: (
    <Icon className={styles.icon} color={Colors.Orange}>
      <WarningIcon />
    </Icon>
  ),
  green: (
    <Icon className={styles.icon} color={Colors.Shamrock}>
      <CheckmarkIcon />
    </Icon>
  )
} as {
  error: ReactNode;
  warn: ReactNode;
  green: ReactNode;
  [arg: string]: ReactNode;
};

function handleContactCsLinkClick() {
  window.dispatchEvent(new CustomEvent('UPDATE_UI.app-chrome.CONTACT_CS'));
}

const StatusLabel = ({ label, message, showContactCs, type }: Props) => {
  const spanClasses = [styles[type]];

  const statusLabel = (
    <div className={styles.wrapper}>
      {icons[type]}
      <span className={classnames(spanClasses)}>{label}</span>
    </div>
  );

  return (
    <Tooltip trigger={statusLabel} className={styles.tooltip}>
      {message}

      {showContactCs && (
        <React.Fragment>
          {' '}
          If the issue persists, please
          <a onClick={handleContactCsLinkClick}>get in touch with our support team!</a>
        </React.Fragment>
      )}
    </Tooltip>
  );
};

export default StatusLabel;
