import { hot } from 'react-hot-loader/root';

import React, { ReactNode, useEffect, useState } from 'react';

import { ButtonLink } from '@unbounce/ui-components';

import Header from './../Basic/Headers/Header';
import Modal from './../Modal/Modal';
import ModalFooter from './../Modal/ModalFooter';

declare global {
  interface Window {
    Brightback: any;
  }
}

interface BrightbackProps {
  title: string;
  buttonId: string;
  href: string;
  label: string;
  appId: string;
  firstName: string;
  lastName: string;
  userEmail: string;
  saveReturnUrl: string;
  cancelConfirmationUrl: string;
  internalId: string;
  type: string;
  cancelModalContent: string;
}

interface BrightbackResponse {
  valid: boolean;
  url: string;
}

const BrightbackHeader = ({
  title,
  buttonId,
  href,
  label,
  appId,
  firstName,
  lastName,
  userEmail,
  saveReturnUrl,
  cancelConfirmationUrl,
  internalId,
  type,
  cancelModalContent
}: BrightbackProps): ReactNode => {
  const [localHref, setHref] = useState(href);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [brightBack, setBrightBack] = useState(false);

  useEffect(() => {
    try {
      //@ts-ignore: Unreachable code error
      window.Brightback = new Brightback();

      setBrightBack(window.Brightback.el !== null);

      if (window.Brightback.el !== null) {
        window.Brightback.handleDataPromise({
          app_id: appId,
          first_name: firstName,
          last_name: lastName,
          email: userEmail,
          save_return_url: saveReturnUrl,
          cancel_confirmation_url: cancelConfirmationUrl,
          account: {
            internal_id: internalId
          }
        }).then((resp: BrightbackResponse) => {
          resp.valid ? setHref(resp.url) : setHref('#');
        });
      } else {
        setHref('#');
      }
    } catch (err) {
      //swallow error and show "Canel button". Old flow will be triggered on click
    }
  }, []);

  const openModal = (action: boolean) => {
    setModalIsOpen(action);
  };

  return (
    <>
      <Header title={title}>
        <span id={buttonId}>
          <ButtonLink
            href={localHref}
            remove={type === 'remove'}
            onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
              e.preventDefault();

              if (!brightBack) {
                openModal(true);
              } else {
                // Kissmetrics
                window._kmq.push([
                  'record',
                  'Brightback.ButtonClicked',
                  { account_UUID: internalId }
                ]);

                window.location.href = localHref;
              }
            }}
          >
            {label}
          </ButtonLink>
        </span>
      </Header>
      <Modal
        title="Cancel My Plan"
        modalIsOpen={modalIsOpen}
        handleCloseModal={() => openModal(false)}
      >
        <div dangerouslySetInnerHTML={{ __html: cancelModalContent }} />
        <ModalFooter>
          <ButtonLink secondary onClick={() => openModal(false)}>
            Keep My Current Plan
          </ButtonLink>
          <ButtonLink remove={type === 'remove'} href={href.replace('_modal', '')}>
            Continue to Next Page
          </ButtonLink>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default hot(BrightbackHeader);
