import styled from 'styled-components';
import { ModalBody, ModalFooter } from '@unbounce/ui-components';
import React from 'react';
import ProgressNodeMeter from './../components/ProgressNodeMeter';
import colors from './../helpers/colors';

const { Emperor, Gray, Silver, White } = colors;

export const Body = styled(ModalBody)`
  display: grid;
  grid-gap: 10px;
  padding: 4.5vh 7vw !important;
`;

export const Previews = styled.div`
  display: grid;
  grid-gap: 3vw;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto 1fr 1fr;
  margin-top: 3vh;
  margin-bottom: 3vh;

  @media (max-width: 1500px) {
    grid-gap: 50px;
  }
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${Emperor};
`;

export const SubDescription = styled.div`
  color: ${Gray};
`;

export const Divider = styled.div`
  width: 1px;
  height: 100%;
  background: ${Silver};
  justify-self: center;
`;

const ProgressWrap = styled.div`
  padding: 30px 120px;
  border-bottom: 1px solid ${Silver};
  position: relative;
`;

export const CloseX = styled.button<{ light?: boolean }>`
  width: 18px;
  height: 18px;
  display: grid;
  position: absolute;
  top: 12px;
  right: 12px;
  background: transparent;
  border: 0 none;

  :before,
  :after {
    width: 100%;
    height: 3px;
    background: ${({ light }) => (light ? White : Emperor)};
    transform: rotate(45deg);
    display: block;
    content: '';
    transform-origin: center;
    grid-row: 1;
    grid-column: 1;
    position: absolute;
    pointer-events: none;
  }

  :after {
    transform: rotate(135deg);
  }
`;

export const MediaBox = styled.iframe`
  max-width: 100%;
  min-height: 300px;
  width: 100%;
  border: 0 none;
  margin: 10px 0;
`;

export const SplitFooter = styled(ModalFooter)`
  justify-content: space-between !important;
`;

export const Progress = ({ page, closeModal }: { page: number; closeModal: () => void }) => (
  <ProgressWrap>
    <ProgressNodeMeter
      current={page}
      progressive
      numbered
      checked
      labels={['Create Variants', 'Turn on Smart Traffic', 'Publish']}
    />
    <CloseX onClick={closeModal} />
  </ProgressWrap>
);
