import React from "react";

import styles from "./ModalHeader.css";

interface ModalHeaderProps {
  title: string;
  handleCloseModal: () => void;
}

const ModalHeader = ({ title, handleCloseModal }: ModalHeaderProps) => (
  <header className={styles.header}>
    <h2 className={styles.title}>{title}</h2>
    <svg
      className={styles.close}
      xmlns="http://www.w3.org/2000/svg"
      height="13.9"
      onClick={handleCloseModal}
    >
      <path d="M9.7 6.9l5.2-5c0.4-0.4 0.4-1.1 0-1.6 -0.4-0.4-1.2-0.4-1.6 0L7.7 5.4H7.6L2 0.3c-0.4-0.4-1.2-0.4-1.6 0S0 1.4 0.4 1.9l5.2 5 -5.2 5C0 12.3 0 13 0.4 13.5S1.5 14 2 13.6l5.6-5.1h0.1l5.6 5.1c0.4 0.4 1.2 0.4 1.6 0 0.4-0.4 0.4-1.1 0-1.6L9.7 6.9z" />
      <rect x="0" y="0" width="15.3" height="13.9" />
    </svg>
  </header>
);

export default ModalHeader;
