import React, { useEffect, useState } from 'react';

import {
  Button,
  FieldValidation,
  InputField,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@unbounce/ui-components';

import styles from './EntriDomainInputModal.css';

declare global {
  interface Window {
    __getEntriErrorMessage: (domainName: string) => string;
    __createDomainWithEntri: (domainName: string) => Promise<void>;
  }
}

type Props = {
  onClose: () => void;
};

const EntriDomainInputModal = ({
  onClose
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [entriDomainInputBlurred, setEntriDomainInputBlurred] = useState(false);
  const [domainForEntri, setDomainForEntri] = useState('');
  const [entriDomainErrorMessage, setEntriDomainErrorMessage] = useState('');
  const entriConnectDisabled = !domainForEntri || Boolean(entriDomainErrorMessage) || loading;
  const showEntri = async () => {
    setLoading(true);

    window.dispatchEvent(
      new CustomEvent('UnbounceUITrackingEvent', {
        detail: {
          event_type: 'domains_connect_domain_click',
          workflow: 'domains'
        }
      })
    );

    await window.__createDomainWithEntri(domainForEntri)
      .then(() => {
        onClose();
      })
      .catch(() => {
        // Not necessarily an error - user could have exited. Reload for the flash message.
        window.location.reload();
      });
  };

  useEffect(() => {
    if (!entriDomainInputBlurred) return;

    if (!domainForEntri) {
      setEntriDomainErrorMessage('');
      return;
    }

    setEntriDomainErrorMessage(
      window.__getEntriErrorMessage(domainForEntri)
    );
  }, [domainForEntri, entriDomainInputBlurred]);

  return <Modal isOpen={true}>
    <ModalHeader onClose={onClose}>Connect a New Custom Domain</ModalHeader>
    <ModalBody>
      <p>What is the domain you would like to use with Unbounce?</p>

      <div className={styles.entriInputWrapper}>
        <InputField
          autofocus
          placeholder="try.example.com"
          value={domainForEntri}
          onChange={(ev) => setDomainForEntri(ev.currentTarget.value)}
          onBlur={() => setEntriDomainInputBlurred(true)}
        />
        <FieldValidation display="bottom" isVisible={Boolean(entriDomainErrorMessage)}>
          {entriDomainErrorMessage}
        </FieldValidation>
      </div>

      <p>
        We <strong>highly recommend</strong> using a sub-domain like <code>try</code> or&nbsp;
        <code>get</code>, but you can also use <code>www</code> if you don't already have a
        website on that domain.
      </p>
    </ModalBody>
    <ModalFooter>
      <Button secondary onClick={onClose}>
        Cancel
      </Button>
      <Button
        primary
        onClick={showEntri}
        disabled={entriConnectDisabled}
        data-testid="add-new-custom-domain"
        data-kmq={`["record", "CreateNewCustomDomain.ButtonClicked", {"location": "app.domains"}]`}
      >
        {loading ? 'Wait...' : 'Connect Domain'}
      </Button>
    </ModalFooter>
  </Modal>;
};

export default EntriDomainInputModal;
