import { Colors, InfoIcon, Icon } from '@unbounce/ui-components';
import React from 'react';
import styles from './RetryModalContent.css';

const RetryModalContent = () => (
  <div className={styles.wrapper}>
    <Icon color={Colors.Emperor}>
      <InfoIcon />
    </Icon>
    <div className={styles.text}>
      <p>
        Heads up! Before you click retry, make sure you followed your domain provider’s instructions
        to correctly{' '}
        <a
          href="https://documentation.unbounce.com/hc/en-us/articles/203661044-Connecting-Your-Domain-to-Unbounce"
          target="_blank"
        >
          set up your CNAME
        </a>{' '}
        to point to unbouncepages.com. <strong>This should be done first.</strong>
      </p>
      <p>
        Once you hit retry, the system will keep trying to set up the domain configuration at
        regular intervals over a few days.
      </p>
    </div>
  </div>
);

export default RetryModalContent;
