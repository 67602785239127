import React from 'react';
import Header from '../../Basic/Headers/Header';
import { ButtonLink } from '@unbounce/ui-components';

const getTitle = ({ query, userCount }: { query: string; userCount: string }) => {
  return query ? `Search ${query}` : `Users (${userCount})`;
};

interface UsersHeaderProps {
  userCount: string;
  query: string;
  newInvitationPath: string;
}

const UsersHeader = ({ userCount, query, newInvitationPath }: UsersHeaderProps) => (
  <Header title={getTitle({ userCount, query })}>
    <ButtonLink
      href={newInvitationPath}
      data-remote="true"
      data-kmq={`["record", "CreateNew.ButtonClicked", {"location": "app.users"}]`}
      className="js-overlay-link"
    >
      Add a User
    </ButtonLink>
  </Header>
);

export default UsersHeader;
