import { hot } from 'react-hot-loader/root';
import React from 'react';
import Header from './Header';

import { ButtonLink } from '@unbounce/ui-components';

interface ScriptManagerProps {
  title: string;
  newGlobalScriptsPath: string;
}

const ScriptManager = ({ title, newGlobalScriptsPath }: ScriptManagerProps) => (
  <Header title={title}>
    <ButtonLink
      href={`${newGlobalScriptsPath}`}
      data-testid="add-new-script"
      data-remote="true"
      className="js-overlay-link"
      data-kmq={`["record", "CreateNew.ButtonClicked", {"location": "app.scriptManager"}]`}
    >
      Add a Script
    </ButtonLink>
  </Header>
);

export default hot(ScriptManager);
