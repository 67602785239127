import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import HeaderSearch from './HeaderSearch';
import Subheader from './Subheader';

import { ButtonLink } from '@unbounce/ui-components';

import styles from './Users.css';

const getTitle = ({ query, userCount }: { query: string; userCount: string }) =>
  query ? `Search ${query}` : `Users (${userCount})`;

interface UsersHeaderProps {
  userCount: string;
  query: string;
  newInvitationPath: string;
}

const UsersHeader = ({ userCount, query, newInvitationPath }: UsersHeaderProps) => {
  return (
    <Header title={getTitle({ userCount, query })}>
      <ButtonLink
        href={newInvitationPath}
        data-remote="true"
        data-kmq={`["record", "CreateNew.ButtonClicked", {"location": "app.users"}]`}
        className="js-overlay-link"
      >
        Add a User
      </ButtonLink>
    </Header>
  );
};

interface LoadStateProps {
  isActive: boolean;
  isPending: boolean;
}

interface UsersSubheaderProps extends LoadStateProps {
  activeUser: {
    label: string;
    href: string;
    count: string;
  };
  pendingInvitation: {
    label: string;
    href: string;
    count: string;
  };
}

const UsersSubheader = ({
  activeUser,
  pendingInvitation,
  isActive,
  isPending
}: UsersSubheaderProps) => (
  <Subheader className={styles.subheader}>
    <div className={styles.filters}>
      <ButtonLink
        secondary
        href={activeUser.href}
        className={`${styles.filterButton} ${isActive ? styles.selected : ''}`}
      >
        <React.Fragment>
          {activeUser.label} {activeUser.count}
        </React.Fragment>
      </ButtonLink>
      <ButtonLink
        secondary
        href={pendingInvitation.href}
        className={`${styles.filterButton} ${isPending ? styles.selected : ''}`}
      >
        <React.Fragment>
          {pendingInvitation.label} {pendingInvitation.count}
        </React.Fragment>
      </ButtonLink>
    </div>
    <HeaderSearch placeholder="Search Users..." styles={styles.searchbar} />
  </Subheader>
);

interface UsersProps {
  activeUser: {
    label: string;
    href: string;
    count: string;
  };
  userCount: string;
  newInvitationPath: string;
  query: string;
  pendingInvitation: {
    label: string;
    href: string;
    count: string;
  };
}

const Users = ({
  activeUser,
  userCount,
  newInvitationPath,
  query,
  pendingInvitation
}: UsersProps) => {
  const [isActive, setIsActive] = useState(false);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    const urlStr = window.location.href;

    if (urlStr.includes('filter')) {
      urlStr.includes(activeUser.href)
        ? setClassname({ active: true, pending: false })
        : setClassname({ active: false, pending: true });
    }
  });

  const setClassname = ({ active, pending }: { active: boolean; pending: boolean }) => {
    setIsActive(active);
    setIsPending(pending);
  };

  return (
    <>
      <UsersHeader {...{ userCount, query, newInvitationPath }} />
      <UsersSubheader
        {...{
          activeUser,
          pendingInvitation,
          isActive,
          isPending
        }}
      />
    </>
  );
};

export default hot(Users);
