import { Chip, ChipColors } from '@unbounce/ui-components';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-flex;
  padding: 20px;
  text-align: left;
  align-items: center;
`;

const PagePill = ({ strategy }: { strategy: string }) => (
  <>
    <Wrapper>
      <Chip color={ChipColors.GullGray_DEPRECATED}>{strategy}</Chip>
    </Wrapper>
  </>
);

export default hot(PagePill);
