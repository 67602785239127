import styled from 'styled-components';
import {
  Colors,
  Tooltip,
  ButtonLink,
  Link,
  CheckmarkIcon,
  IdeaIcon,
  Icon
} from '@unbounce/ui-components';
import React, { useEffect } from 'react';

import colors from './helpers/colors';
import { sentryCaptureMessage } from './helpers/sentry';
import { trackLoopEvent, LoopEvent } from './helpers/loopTracking';

const { Shamrock, UnbounceBlue } = colors;

const Title = styled.div`
  font-weight: bold;
  grid-area: title;
`;

const Description = styled.div`
  grid-area: description;
`;

const EntryButton = styled(ButtonLink)`
  border-radius: 4px;
  grid-area: entry;
  min-width: 90px;
`;

export const InfoBox = ({ url }: { url: string }) => (
  <>
    <Title>Turn on Smart Traffic</Title>
    <Description>
      Apply Smart Traffic to this page to get up to a 20% lift in your conversion rate. It takes 2
      min.
    </Description>
    <EntryButton href={url} onClick={() => sentryCaptureMessage('Clicked on "Let\'s Go button"')}>
      Let's Go!
    </EntryButton>
  </>
);

const CheckIcon = styled(Icon)`
  background: ${Shamrock};
  padding: 3px;
  border-radius: 50px;
  margin-right: 10px;
`;

export const OptimizingBox = () => (
  <>
    <Title>
      <CheckIcon color={Colors.White}>
        <CheckmarkIcon />
      </CheckIcon>
      Smart Traffic Enabled
    </Title>
    <Description>
      <span style={{ maxWidth: 210, display: 'block' }}>
        I’m working hard to make sure your customers see the right variant.
      </span>
    </Description>
  </>
);

const InsightsIcon = styled(Icon)`
  margin-right: 5px;
  padding: 3px;
  height: 24px !important;
  width: 24px !important;
`;

const InsightsLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

export const InsightsBox = ({
  url,
  insightCount,
  pageUuid
}: {
  url: string;
  insightCount: number;
  pageUuid: string;
}) => {
  useEffect(() => {
    trackLoopEvent(LoopEvent.InsightBadgeViewed, pageUuid);
  }, []);

  const onMouseEnter = () => trackLoopEvent(LoopEvent.InsightBadgeHovered, pageUuid);
  const onClick = () => trackLoopEvent(LoopEvent.InsightBadgeClicked, pageUuid);

  return (
    <div {...{ onMouseEnter }}>
      <Tooltip
        trigger={
          <InsightsLink href={url} target="_self" {...{ onClick }}>
            <InsightsIcon color={Colors.UnbounceBlue}>
              <IdeaIcon />
            </InsightsIcon>
            <span style={{ color: UnbounceBlue, paddingRight: 5 }}>
              {insightCount} Insight{insightCount > 1 ? 's' : ''} Found!
            </span>
          </InsightsLink>
        }
      >
        We found {insightCount > 1 ? 'some improvements' : 'an improvement'} you could be making on
        your page!
      </Tooltip>
    </div>
  );
};
